

/* Home.js */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap"); */

/* body {
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;
} */

.hero-section {
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(15deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0,0.2));
  z-index: 1;
}

.hero-content {
  position: relative;
  z-index: 2;
  padding: 2rem;
  color: white;
  height: 100vh;
  display: flex;
  align-items: center;
}

.content-wrapper {
  max-width: 800px;
  margin-left: 5.6%;
  margin-top: 3rem; 
}

.hero-title {
  font-size: 3rem; 
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1rem;
  color: white;
  margin-top: 1rem; 
}

.hero-subtitle {
  font-size: 2rem; 
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  color: white;
  margin-top: 0.5rem; 
}

.hero-description {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 2rem;
  max-width: 600px;
  color: rgba(255, 255, 255, 0.9);
}


.cta-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: white;
  color: #1a365d;
  font-weight: 600;
  border-radius: 6px;
  text-decoration: none;
  transition: all 0.3s ease;
  border: 2px solid white;
  cursor: pointer;
}

.cta-button:hover {
  background-color: transparent;
  color: white;
}

@media screen and (max-width: 1024px) {
  .hero-title{
    font-size: 2.5rem; 
    margin-top: 1.5rem;
  }

  .hero-subtitle {
    font-size: 1.8rem; 
    margin-top: 1rem; 
  }

  .content-wrapper {
    margin-left: 6%;
    margin-top: 2.5rem; 
  }
}

@media screen and (max-width: 768px) {
  .hero-title{
    font-size: 2rem; 
    margin-top: 1rem; 
  }

  .hero-subtitle {
    font-size: 1.5rem; 
    margin-top: 0.8rem; 
  }

  .hero-description {
    font-size: 1rem;
  }

  .content-wrapper {
    margin-left: 4%;
    margin-top: 2rem; 
  }
}

@media screen and (max-width: 480px) {
  .hero-title{
    font-size: 1.8rem; 
    margin-top: 0.8rem; 
  }

  .hero-subtitle {
    font-size: 1.3rem; 
    margin-top: 0.5rem; 
  }

  .hero-content {
    padding: 1rem; 
  }

  .content-wrapper {
    margin-left: 2%;
    margin-top: 1.5rem; 
  }

  .cta-button {
    padding: 0.8rem 1.5rem; 
  }
}
@media screen and (max-width: 320px) {
  .hero-title{
    font-size: 1rem; 
    margin-top: 2rem; 
  }

  .hero-subtitle {
    font-size: 1.3rem; 
    margin-top: 0.5rem; 
  }

  .hero-content {
    padding: 1rem; 
  }

  .content-wrapper {
    margin-left: 2%;
    margin-top: 1.5rem; 
  }

  .cta-button {
    padding: 0.8rem 1.5rem; 
  }
}

/* Animation classes */
.fade-in {
  animation: fadeIn 1s ease-in;
}

.slide-up {
  animation: slideUp 0.8s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.counters {
  background-image: url("../assets/img/counterBg.jpg");
}

.common-section {
  margin-left: 5.6%;
}

/* Clients.css */
.slick-slide {
  outline: none; /* Removes focus outline */
}

.slick-slide img {
  display: block;
  margin: 0 auto;
  pointer-events: none; /* Prevent focus styling on the image */
  transition: transform 0.3s ease; /* Optional hover effect */
}

.slick-slide:hover img {
  transform: scale(1.1); /* Slight zoom on hover */
}


/* .font-heading {
  font-size: 3rem; 
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1rem;
  margin-top: 1rem; 
} */