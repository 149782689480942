.logo {
    animation-name: spin;
    animation-duration: 8s;
    animation-delay: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .custom-background {
    background-color: #b71c1c;
    color: white;
    display: inline-block;
    padding: 3px 5px;
    border-radius: 4px;
    white-space: nowrap;
    letter-spacing: 5px;
  }

  .first-line{
    color:#b71c1c;
    font-size: 1.875rem;
}
  @media screen and(max-width:320px){
    .first-line{
        font-size:30px;
        /* margin-left: 80px; */
    }
    .custom-background{
        font-size:30px;
    }
  }